/* eslint-disable no-console */
import _ from "lodash";
import numeral from "numeral";
import { db, timestamp } from "@/firebase";

function getQualifierValue(header, value) {
  const { qualifierFormat, qualifierComp } = header;
  if (
    qualifierFormat === "boolean" ||
    qualifierFormat === "yesno" ||
    qualifierFormat === "truefalse"
  ) {
    const valLowerCase = value.toLowerCase();
    if (valLowerCase === "yes" || valLowerCase === "true") {
      return true;
    }
    return false;
  } else if (qualifierFormat === "number") {
    const valNumber = parseFloat(value.toString().replace(/[^0-9.-]/g, ""));
    const comparing = qualifierComp || 100;
    return valNumber >= comparing;
  }
  return false;
}

function getQualifierImg(header, value) {
  const qualifierValue = getQualifierValue(header, value);
  if (qualifierValue) {
    return "tick.svg";
  }
  return "cross.svg";
}

function getColumnValue(
  qualifierHeaders,
  accountLabel,
  avatar,
  firstname,
  lastname,
  firstLastInitial,
  row,
  rowIndex,
  header
) {
  const { type, column, format, rounding } = header;

  if (column === "Icon") {
    let allQualified = true;

    qualifierHeaders.forEach((qHeader) => {
      allQualified =
        allQualified && getQualifierValue(qHeader, row[qHeader.column] || "");
    });

    return allQualified;
  } else if (column === "Rank") {
    return rowIndex + 1;
  } else if (column === "Avatar") {
    return avatar;
  } else if (column === "First Name + Last Initial") {
    return firstLastInitial;
  } else if (column === "First Name") {
    return firstname;
  } else if (column === "Last Name") {
    return lastname;
  } else if (column === "Company Title" || column === "Full Name") {
    return accountLabel;
  } else if (type === "qualifier") {
    return getQualifierImg(header, row[column] || "");
  } else if (type !== "number") {
    return row[column];
  } else {
    const numberValue = row[column] || "";
    const numberString = numberValue.toString().replace(/[^0-9.-]/g, "");
    const rounded = numeral(numberString).format(rounding);
    const isAppend = format !== "%";
    return isAppend ? `${format}${rounded}` : `${rounded}${format}`;
  }
}

const state = {
  leaderboards: [],
  leaderboardsRaw: [],
  loadingLeaderboards: false,
  loadingCurrentLeaderboard: false,
  currentLeaderboard: null,
  currentLeaderboardRaw: {},
  pendingLeaderboardUpdates: {},
  pendingLeaderboardRawUpdates: {},
};

const actions = {
  async loadLeaderboards({ commit, getters }) {
    commit("setLoadingCards", true);
    commit("setLeaderboards", []);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("leaderboards")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const leaderboards = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      leaderboards.push({
        id: doc.id,
        title: data.title,
        status: data.status,
        mode: data.mode,
        selectedDatabucket: data.selectedDatabucket || "",
        created: data.created.toDate(),
        updated: data.updated.toDate(),
      });
    });

    commit("setLeaderboards", leaderboards);
    commit("setLoadingCards", false);
  },

  async loadLeaderboardsRaw({ commit, getters }) {
    commit("setLeaderboardsRaw", []);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("leaderboardsRaw")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const leaderboardsRaw = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      leaderboardsRaw.push({
        id: doc.id,
        csvName: data.csvName,
        updated: data.updated,
      });
    });

    commit("setLeaderboardsRaw", leaderboardsRaw);
  },

  async loadLeaderboardsByDatabucket({ getters, commit }, databucketId) {
    commit("setLeaderboards", []);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("leaderboards")
        .where("selectedDatabucket", "==", databucketId)
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const leaderboards = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      leaderboards.push({
        ...data,
        id: doc.id,
        created: data.created && data.created.toDate(),
        updated: data.updated && data.updated.toDate(),
      });
    });
    commit("setLeaderboards", leaderboards);
  },

  async loadCurrentLeaderboard({ commit, getters }, leaderboardId) {
    commit("setLoadingCurrentLeaderboard", true);
    commit("setCurrentLeaderboard", null);
    commit("setCurrentLeaderboardRaw", {});
    commit("clearLeaderboardPendingUpdates");
    commit("clearLeaderboardRawPendingUpdates");

    const leaderboardApiCall = db
      .collection("programs")
      .doc(getters.programId)
      .collection("leaderboards")
      .doc(leaderboardId)
      .get();

    let leaderboardSnapshot;

    try {
      leaderboardSnapshot = await leaderboardApiCall;
    } catch (e) {
      throw "Error occured when fetcing a leaderboard.";
    }

    const leaderboardData = leaderboardSnapshot.data();

    if (!leaderboardData) {
      throw "No such a leaderboard";
    }

    // Note: Give default values if it doesn't exist
    // It's necessary in store level for deep comparison of pending updates
    const leaderboard = {
      ...leaderboardData,
      id: leaderboardSnapshot.id,
      participantType: leaderboardData.participantType || "member",
      mode: leaderboardData.mode || "dynamic",
      order: leaderboardData.order || 0,
      selectedFilters: leaderboardData.selectedFilters || [],
      filteredEntityMap: leaderboardData.filteredEntityMap || [],
      homeMappings: leaderboardData.homeMappings || {},
      remarkMappings: leaderboardData.remarkMappings || [],
      headerMappings: leaderboardData.headerMappings || [],
      iconMappings: leaderboardData.iconMappings || {},
      columnMappings: leaderboardData.columnMappings || [],
      rankMappings: leaderboardData.rankMappings || {
        sortDir: "desc",
        winRows: 1,
        maxRows: 0,
      },
      created: leaderboardData.created.toDate(),
      updated: leaderboardData.updated.toDate(),
      selectedDatabucket: leaderboardData.selectedDatabucket,
    };
    this.commit("setBreadCrumbDetail", leaderboard.title);
    const leaderboardRawApiCall =
      leaderboard.mode === "static"
        ? db
            .collection("programs")
            .doc(getters.programId)
            .collection("leaderboardsRaw")
            .doc(leaderboardId)
            .get()
        : db
            .collection("programs")
            .doc(getters.programId)
            .collection("databuckets")
            .doc(leaderboard.selectedDatabucket || "dummyId")
            .collection("results")
            .doc("total")
            .get();

    let leaderboardRawSnapshot;
    try {
      leaderboardRawSnapshot = await leaderboardRawApiCall;
    } catch (e) {
      throw "Error occured when fetching leaderboard raw.";
    }
    const leaderboardRawData = leaderboardRawSnapshot.data();

    let leaderboardRaw = {};

    if (leaderboardRawData) {
      leaderboardRaw = {
        updated: leaderboardRawData.updated.toDate(),
        csvData: JSON.parse(leaderboardRawData.csvData),
        csvName: leaderboardRawData.csvName,
      };
    }

    commit("setCurrentLeaderboard", leaderboard);
    commit("setCurrentLeaderboardRaw", leaderboardRaw);
    commit("setLoadingCurrentLeaderboard", false);
  },

  fakeLoadCurrentLeaderboard({ commit }, payload) {
    const { leaderboardData, leaderboardRawData } = payload;

    const leaderboard = {
      ...leaderboardData,
      id: leaderboardData.id,
      participantType: leaderboardData.participantType || "company",
      mode: leaderboardData.mode || "dynamic",
      order: leaderboardData.order || 0,
      selectedFilters: leaderboardData.selectedFilters || [],
      filteredEntityMap: leaderboardData.filteredEntityMap || [],
      homeMappings: leaderboardData.homeMappings || {},
      remarkMappings: leaderboardData.remarkMappings || [],
      headerMappings: leaderboardData.headerMappings || [],
      iconMappings: leaderboardData.iconMappings || {},
      columnMappings: leaderboardData.columnMappings || [],
      rankMappings: leaderboardData.rankMappings || {
        sortDir: "desc",
        winRows: 1,
        maxRows: 0,
      },
    };

    const leaderboardRaw = {};
    if (leaderboardRawData) {
      leaderboardRaw.csvData = JSON.parse(leaderboardRawData.csvData);
      leaderboardRaw.csvName = leaderboardRawData.csvName;
    }

    commit("setCurrentLeaderboard", leaderboard);
    commit("setCurrentLeaderboardRaw", leaderboardRaw);
  },

  async createLeaderboard({ dispatch, commit, getters }, payload) {
    console.log(payload);
    const leaderboardsRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("leaderboards");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await leaderboardsRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    const leaderboard = {
      ...payload,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
      created: timestamp,
      updated: timestamp,
    };

    let newLeaderboardRef;
    try {
      newLeaderboardRef = await leaderboardsRef.add(leaderboard);
    } catch (e) {
      throw "Error occured when creating a new leaderboard";
    }

    // Note: server time is unavailable until we refetch.
    const tmpLeaderboard = {
      ...leaderboard,
      id: newLeaderboardRef.id,
      created: new Date(),
      updated: new Date(),
    };

    commit("createLeaderboard", tmpLeaderboard);
    dispatch("setSnackbar", "Leaderboard Created.");
  },

  async updateLeaderboard({ getters, dispatch }) {
    const { hasLeaderboardPendingUpdates, hasLeaderboardRawPendingUpdates } =
      getters;

    if (hasLeaderboardRawPendingUpdates) {
      try {
        await Promise.all([
          dispatch("syncLeaderboard"),
          dispatch("syncLeaderboardRaw"),
        ]);
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    if (hasLeaderboardPendingUpdates && !hasLeaderboardRawPendingUpdates) {
      try {
        await dispatch("syncLeaderboard");
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    dispatch("setSnackbar", "Leaderboard Saved.");
  },

  async syncLeaderboard({ commit, getters, state }) {
    const clientNow = new Date();

    const payload = {
      ...state.pendingLeaderboardUpdates,
      remarkData: getters.leaderboardRemarkData,
      headerData: getters.leaderboardHeaderData,
      columnData: getters.leaderboardColumnData,
    };

    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("leaderboards")
        .doc(state.currentLeaderboard.id)
        .update({
          ...payload,
          updated: timestamp,
        });
    } catch (e) {
      console.error(e);
      throw "Error when syncing leaderboard raw";
    }

    commit("patchCurrentLeaderboard", {
      updated: clientNow,
    });
    commit("clearLeaderboardPendingUpdates");
  },

  async syncLeaderboardRaw({ commit, getters, state }) {
    const clientNow = new Date();

    // Note: When fetching data from databucket, there is no need
    // to update the leaderboard raw.
    if (state.currentLeaderboard.mode === "dynamic") {
      commit("clearLeaderboardRawPendingUpdates");
      return;
    }

    const payload = {
      ...state.pendingLeaderboardRawUpdates,
    };

    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("leaderboardsRaw")
        .doc(state.currentLeaderboard.id)
        .set(
          {
            ...payload,
            csvData: JSON.stringify(payload.csvData),
            updated: timestamp,
          },
          { merge: true }
        );
    } catch (e) {
      console.error(e);
      throw "Error when syncing leaderboard raw";
    }

    commit("patchCurrentLeaderboardRaw", {
      updated: clientNow,
    });
    commit("clearLeaderboardRawPendingUpdates");
  },

  async deleteLeaderboard({ commit, getters, state }) {
    const currentLeaderboardId = state.currentLeaderboard.id;
    try {
      await Promise.all([
        db
          .collection("programs")
          .doc(getters.programId)
          .collection("leaderboards")
          .doc(currentLeaderboardId)
          .delete(),
        db
          .collection("programs")
          .doc(getters.programId)
          .collection("leaderboardsRaw")
          .doc(currentLeaderboardId)
          .delete(),
      ]);
    } catch (e) {
      throw "error when deleting a leaderboard";
    }

    commit("deleteLeaderboard", currentLeaderboardId);
  },

  async selectDatabucket({ getters, commit }, databucketId) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    let data;
    try {
      const totalResultRef = await databucketRef
        .collection("results")
        .doc("total")
        .get();
      data = totalResultRef.data();
    } catch (e) {
      throw "Error when fetching a databucket result";
    }

    commit("patchCurrentLeaderboard", {
      selectedDatabucket: databucketId,
    });
    commit("patchCurrentLeaderboardRaw", {
      csvData: JSON.parse(data.csvData),
      csvName: data.csvName,
      updated: data.updated.toDate(),
    });
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentLeaderboard({ commit }, payload) {
    commit("patchCurrentLeaderboard", payload);
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentLeaderboardRaw({ commit }, payload) {
    commit("patchCurrentLeaderboardRaw", payload);
  },
};

const mutations = {
  setLeaderboards(state, payload) {
    state.leaderboards = payload;
  },

  setLeaderboardsRaw(state, payload) {
    state.leaderboardsRaw = payload;
  },

  setLoadingLeaderboards(state, payload) {
    state.loadingLeaderboards = payload;
  },

  setCurrentLeaderboard(state, payload) {
    state.currentLeaderboard = payload;
  },

  setCurrentLeaderboardRaw(state, payload) {
    state.currentLeaderboardRaw = payload;
  },

  setLoadingCurrentLeaderboard(state, payload) {
    state.loadingCurrentLeaderboard = payload;
  },

  createLeaderboard(state, payload) {
    state.leaderboards = [...state.leaderboards, payload];
  },

  patchCurrentLeaderboard(state, payload) {
    state.currentLeaderboard = {
      ...state.currentLeaderboard,
      ...payload,
    };

    state.pendingLeaderboardUpdates = {
      ...state.pendingLeaderboardUpdates,
      ...payload,
    };
  },

  patchCurrentLeaderboardRaw(state, payload) {
    state.currentLeaderboardRaw = {
      ...state.currentLeaderboardRaw,
      ...payload,
    };

    state.pendingLeaderboardRawUpdates = {
      ...state.pendingLeaderboardRawUpdates,
      ...payload,
    };
  },

  clearLeaderboardPendingUpdates(state) {
    state.pendingLeaderboardUpdates = {};
  },

  clearLeaderboardRawPendingUpdates(state) {
    state.pendingLeaderboardRawUpdates = {};
  },

  deleteLeaderboard(state, payload) {
    state.leaderboards = state.leaderboards.filter(
      (item) => item.id === payload
    );
  },
};

const getters = {
  leaderboards(state) {
    return state.leaderboards;
  },

  leaderboardsRaw(state) {
    return state.leaderboardsRaw;
  },

  loadingLeaderboards(state) {
    return state.loadingLeaderboards;
  },

  currentLeaderboard(state) {
    return state.currentLeaderboard;
  },

  // leaderboardEntityMap(state, getters) {
  //   return getters.currentLeaderboard.participantType === 'member'
  //     ? getters.memberAccountKeysMap
  //     // : getters.companyAccountKeysMap
  //     : state.currentLeaderboard.filteredEntityMap
  // },

  leaderboardEntityMap(state, getters) {
    const leaderboard = getters.currentLeaderboard;
    if (getters.currentLeaderboard.participantType === "company") {
      if (leaderboard.selectedFilters.length > 0) {
        return leaderboard.filteredEntityMap;
      } else {
        return getters.companyAccountKeysMap;
      }
    } else if (getters.currentLeaderboard.participantType === "member") {
      if (leaderboard.selectedFilters.length > 0) {
        return leaderboard.filteredEntityMap;
      } else {
        return getters.memberAccountKeysMap;
      }
    } else {
      return [];
    }
  },

  leaderboardCsvHeaders(state) {
    const { csvData } = state.currentLeaderboardRaw;
    if (!csvData || !csvData.length) {
      return [];
    }
    return csvData[0] || [];
  },

  leaderboardCsvBody(state, getters) {
    const { csvData } = state.currentLeaderboardRaw;
    if (!csvData || !csvData.length) {
      return [];
    }

    return csvData.slice(1).map((row) => {
      return getters.leaderboardCsvHeaders.reduce(
        (rowResult, header, headerIndex) => {
          return {
            ...rowResult,
            [header]: row[headerIndex],
          };
        },
        {}
      );
    });
  },

  leaderboardSystemHeaders(state) {
    const calculated = ["Rank", "Icon"];

    return state.currentLeaderboard.participantType === "member"
      ? [
          "Full Name",
          "First Name",
          "Last Name",
          "Avatar",
          "First Name + Last Initial",
          ...calculated,
        ]
      : ["Company Title", ...calculated];
  },

  leaderboardMatchingData(state, getters) {
    const { accountKeyColumn } = state.currentLeaderboard;
    const { leaderboardCsvBody, leaderboardEntityMap } = getters;

    if (!leaderboardCsvBody.length) {
      return [];
    }

    return leaderboardCsvBody.filter((item) => {
      const accountKeyValue = item[accountKeyColumn];
      return !!(accountKeyValue && leaderboardEntityMap[accountKeyValue]);
    });
  },

  leaderboardAccountsMap(state, getters) {
    const { accountKeyColumn } = state.currentLeaderboard;
    const { leaderboardMatchingData, leaderboardEntityMap } = getters;

    if (!leaderboardMatchingData.length) {
      return [];
    }

    const allAccountKeyValues = leaderboardMatchingData.map(
      (item) => item[accountKeyColumn]
    );
    const uniqAccountKeyValues = _.uniq(allAccountKeyValues);

    return uniqAccountKeyValues.map((item) => {
      const label = leaderboardEntityMap[item].title;

      return {
        label,
        value: item,
        ...leaderboardEntityMap[item],
      };
    });
  },

  leaderboardSortData(state, getters) {
    const {
      rankMappings: { sortColumn, sortDir },
    } = state.currentLeaderboard;

    if (!sortColumn) {
      return getters.leaderboardMatchingData;
    }

    // Note: Concat is for preventing side effect
    // Javascript sort function mutates additional array and
    // concat helps you to copy the array before sorting
    return getters.leaderboardMatchingData.concat().sort((rowA, rowB) => {
      const valueA = parseFloat(rowA[sortColumn]);
      const valueB = parseFloat(rowB[sortColumn]);
      return sortDir === "asc" ? valueA - valueB : valueB - valueA;
    });
  },

  leaderboardColumnData(state, getters) {
    const { rankMappings, columnMappings, accountKeyColumn } =
      state.currentLeaderboard;
    const { leaderboardSortData, leaderboardEntityMap } = getters;

    const qualifierHeaders = columnMappings.filter(
      (item) => item.type === "qualifier"
    );

    const subData = rankMappings.maxRows
      ? leaderboardSortData.slice(0, rankMappings.maxRows)
      : leaderboardSortData;

    const data = subData.map((row, rowIndex) => {
      const columnDataItem = {
        id: leaderboardEntityMap[row[accountKeyColumn]].id,
        accountKey: row[accountKeyColumn],
      };

      const accountLabel = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .title;
      const avatar = (leaderboardEntityMap[row[accountKeyColumn]] || {}).avatar;
      const firstLastInitial = (
        leaderboardEntityMap[row[accountKeyColumn]] || {}
      ).firstLastInitial;
      const firstname = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .firstname;
      const lastname = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .lastname;

      const columnDataItemValues = {};
      columnMappings.forEach((header) => {
        const columnDataItemValue = getColumnValue(
          qualifierHeaders,
          accountLabel,
          avatar,
          firstname,
          lastname,
          firstLastInitial,
          row,
          rowIndex,
          header
        );
        columnDataItemValues[header.uid] = columnDataItemValue;
      });
      columnDataItem.values = columnDataItemValues;
      return columnDataItem;
    });

    const iconColumn = columnMappings.find(
      (header) => header.column === "Icon"
    );
    if (iconColumn) {
      let winningRows = 0;
      data.forEach((row) => {
        if (row.values[iconColumn.uid] && winningRows < rankMappings.winRows) {
          row.isWinner = true;
          winningRows += 1;
        }
      });
    }

    return data;
  },

  leaderboardHeaderData(state, getters) {
    const { rankMappings, headerMappings, columnMappings, accountKeyColumn } =
      state.currentLeaderboard;
    const { leaderboardSortData, leaderboardEntityMap } = getters;

    const qualifierHeaders = columnMappings.filter(
      (item) => item.type === "qualifier"
    );

    const data = leaderboardSortData.map((row, rowIndex) => {
      const headerDataItem = {
        id: leaderboardEntityMap[row[accountKeyColumn]].id,
        accountKey: row[accountKeyColumn],
      };

      const accountLabel = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .title;
      const avatar = (leaderboardEntityMap[row[accountKeyColumn]] || {}).avatar;
      const firstLastInitial = (
        leaderboardEntityMap[row[accountKeyColumn]] || {}
      ).firstLastInitial;
      const firstname = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .firstname;
      const lastname = (leaderboardEntityMap[row[accountKeyColumn]] || {})
        .lastname;

      const headerDataItemValues = {};

      // Note: Use qualifier columns from column mappings;
      headerMappings.concat(qualifierHeaders).forEach((header) => {
        const headerDataItemValue = getColumnValue(
          qualifierHeaders,
          accountLabel,
          avatar,
          firstLastInitial,
          firstname,
          lastname,
          row,
          rowIndex,
          header
        );
        headerDataItemValues[header.uid] = headerDataItemValue;
      });
      headerDataItem.values = headerDataItemValues;
      return headerDataItem;
    });

    const iconColumn = headerMappings.find(
      (header) => header.column === "Icon"
    );
    if (iconColumn) {
      let winningRows = 0;
      data.forEach((row) => {
        if (row.values[iconColumn.uid] && winningRows < rankMappings.winRows) {
          row.isWinner = true;
          winningRows += 1;
        }
      });
    }

    return data;
  },

  leaderboardRemarkData(state, getters) {
    const { remarkMappings, accountKeyColumn } = state.currentLeaderboard;
    const { leaderboardSortData, leaderboardEntityMap } = getters;

    const data = leaderboardSortData.map((row) => {
      const headerDataItem = {
        id: leaderboardEntityMap[row[accountKeyColumn]].id,
        accountKey: row[accountKeyColumn],
      };
      const headerDataItemValues = {};

      remarkMappings.forEach((header) => {
        const headerDataItemValue = row[header.column];
        headerDataItemValues[header.uid] = headerDataItemValue;
      });
      headerDataItem.values = headerDataItemValues;
      return headerDataItem;
    });

    return data;
  },

  hasLeaderboardPendingUpdates(state) {
    return Object.keys(state.pendingLeaderboardUpdates).length !== 0;
  },
  hasLeaderboardRawPendingUpdates(state) {
    return Object.keys(state.pendingLeaderboardRawUpdates).length !== 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
